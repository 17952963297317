@use "./base";
@use "./abstracts/helpers";

// Button Spinner
@use "./components/button-spinner";

// Onboarding Layout component
@use "./layout/onboarding";

/*******Dashboard Layout*******/
@use "./components/dashboard-header";

// Dashboard Sidebar
@use "./components/user-menu";

/*******Pages*******/
@use "./pages/approval-chain";

@use "./pages/project";

@use "./pages/viewproject";

@use "./pages/createproject";

@use "./pages/users";
@use "./pages/forgotpassord";

/*******Components*******/

// Input Field
@use "./components/input-field";

// Buttons
@use "./components/buttons";

// Modal Component
@use "./components/modal";

.d-none {
  display: none !important;
}

span.emoji {
  font-size: 22px;
}

.dropdown {
  position: relative;
  display: inline-block;
  top: 0;
  right: 0px;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  background: #000921;
  box-shadow: 0px 6px 20px #00000014;
  border-radius: 6px;
  padding: 12px 18px;
  z-index: 1;
  color: #fff;
  font-size: 12.5px;

  p {
    margin: 8px 0;
    text-align: left;
    cursor: pointer;
  }
}

.dropdown:hover {
  .dropdown-content {
    display: block;
  }
}

::-webkit-scrollbar {
  display: none;
}

.modal-title {
  font-size: 1.1rem !important;
}

button {
  font-size: 13px !important;
  height: 48px !important;
  outline: none !important;
  border: none !important;
}

.btn-primary {
  font-size: 13px !important;
  height: 48px !important;
  background-color: #4f269f !important;
  color: #fff !important;
  outline: none !important;
  border: none !important;
}

.btn-danger {
  font-size: 13px !important;
  height: 48px !important;
  background-color: #ffeef2 !important;
  color: #fa235d !important;
  outline: none !important;
  border: none !important;
}

.input__error {
  font-size: 12px;
  line-height: 0.285;
  color: #fa235d;
  margin-top: 9px;
}

.input__field {
  height: 50px;
  font-size: 15px;
  padding: 15px;
}

.modal-custom-header {
  border-bottom: 1px solid #e9e9e9;
  margin: 0 20px;
  color: #000922;
  padding: 17px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cancel-button {
    width: 15px;
    height: 15px;
    background-color: #ee4e61;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &__inner {
      width: 7px;
      height: 7px;
      background-color: #a4414d;
      border-radius: 25px;
    }
  }
}

.modal-custom-body {
  padding: 20px 30px;
}

.coming-soon {
  h1 {
    padding-top: 10rem;
    text-align: center;
    font-size: 150px;
  }
}
