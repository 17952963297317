@use "../abstracts/mixins" as m;
@use "../abstracts/colors" as c;
@use "../base/typography";

.user-nav {
  padding: 25px 0 15px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9eaec;

  &__header {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 17.5px;
  }

  &__subtext {
    font-size: 14px;
    color: #989ca5;
    font-weight: 400;
    border-left: 1.5px solid #e9eaec;
    padding-left: 10px;
    margin-left: 7px;
  }

  &__filter {
    border-radius: 8px;
    border: 1px solid c.color(primary-100);
    background-color: c.color(primary-200);
    padding: 4px;
    padding-left: 10px;
    margin-left: calc(100vw - 55.5rem);

    @include m.respond(phone-wide) {
      margin-left: 2rem;
    }
    &-body {
      height: 18px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 0rem 0.5rem;
      @include m.respond(phone-wide) {
        list-style-type: none;
        // margin: 0.2rem;
        // padding: 0;
        // text-align: right;
        // float: left;
        margin: 0px;
        padding: 0px;
      }
      &-text {
        padding-top: 0.8rem;
        margin: 0px;
        padding: 0px;
        display: inline-flex;
        @include m.respond(phone-wide) {
          margin: 0px;
          padding: 0px;
          display: inline-flex;
          p {
            font-size: 8px;
            margin: 0px;
            padding: 0px;
            display: inline-flex;
          }
        }
      }
    }
    &-icon {
      padding-left: 9px;
      padding-bottom: 0.2rem;
      svg {
        width: 0.5rem;
        fill: black;
        stroke: black; 
        transition: all .15s ease-in;
      }
    }
    &:hover{
      svg{
        transform: rotate(180deg);
      }
    }
  }
}

.user-container {
  margin-top: 18px;
  border: 1px solid #e9eaec;
  padding: 30px;
  margin-right: 25px;
  border-radius: 8px;
}

.agent-info {
  margin-top: 20px;
  &__container {
    border: 1px solid #e9eaec;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 30px;
  }
}
