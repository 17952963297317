@use "../abstracts/colors" as c;
@use "../abstracts/mixins" as m;

$display: "Nunito", sans-serif;
$paragraph: "Nunito", sans-serif;
$base-font-size: 1rem;

body {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, Roboto, Helvetica,
    Arial, sans-serif;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: 1.25;
  color: c.color(black);
}

@mixin display {
  font-family: $display;
}

@mixin paragraph {
  font-family: $display;
}

%light {
  font-weight: 50;
}

%regular {
  font-weight: 400;
}

%semibold {
  font-weight: 600;
}

%semibolded {
  font-weight: 700;
}

%bold {
  font-weight: 800;
}

// Display Typography

.display {
  &-1 {
    @include display;
    font-size: $base-font-size * 3;
    line-height: 1.271;

    @include m.respond("phone-wide") {
      font-size: 2.5rem;
    }

    @include m.respond("phone-small") {
      font-size: 2.25rem;
    }

    @include m.respond("phone-smallest") {
      font-size: 2rem;
    }

    &-regular {
      @extend .display-1;
      @extend %regular;
    }

    &-semibold {
      @extend .display-1;
      @extend %semibold;
    }

    &-bold {
      @extend .display-1;
      @extend %bold;
    }
  }

  // Display 1 (Small)

  &-1 {
    &-small {
      @include display;
      font-size: $base-font-size * 2.25;
      line-height: 1.278;

      &-regular {
        @extend .display-1-small;
        @extend %regular;
      }

      &-semibold {
        @extend .display-1-small;
        @extend %semibold;
      }

      &-bold {
        @extend .display-1-small;
        @extend %bold;
      }
    }
  }

  // Display 2

  &-2 {
    @include display;
    font-size: $base-font-size * 2.5;
    line-height: 1.275;

    @include m.respond("phone-wide") {
      font-size: 2rem;
    }

    @include m.respond("phone-small") {
      font-size: 1.75rem;
    }

    @include m.respond("phone-smallest") {
      font-size: 1.5rem;
    }

    &-regular {
      @extend .display-2;
      @extend %regular;
    }

    &-semibold {
      @extend .display-2;
      @extend %semibold;
    }

    &-bold {
      @extend .display-2;
      @extend %bold;
    }
  }

  // Dispkay 2 (Small)

  &-2 {
    &-small {
      @include display;
      font-size: $base-font-size * 1.75;
      line-height: 1.286;

      &-regular {
        @extend .display-2-small;
        @extend %regular;
      }

      &-semibold {
        @extend .display-2-small;
        @extend %semibold;
      }

      &-bold {
        @extend .display-2-small;
        @extend %bold;
      }
    }
  }
}

// Heading Typography
.heading {
  // Heading 1
  &-1 {
    @include display;
    font-size: $base-font-size * 2;
    line-height: 1.281;

    &-regular {
      @extend .heading-1;
      @extend %regular;
    }

    &-semibold {
      @extend .heading-1;
      @extend %semibold;
    }

    &-bold {
      @extend .heading-1;
      @extend %bold;
    }
  }

  // Heading 2

  &-2 {
    @include display;
    font-size: $base-font-size * 1.5;
    line-height: 1.291;

    &-light {
      @extend .heading-2;
      @extend %light;
    }

    &-regular {
      @extend .heading-2;
      @extend %regular;
    }

    &-semibold {
      @extend .heading-2;
      @extend %semibold;
    }

    &-semibolded {
      @extend .heading-2;
      @extend %semibolded;
    }

    &-bold {
      @extend .heading-2;
      @extend %bold;
    }
  }

  // Heading 3

  &-3 {
    @include display;
    font-size: $base-font-size * 1.3;
    line-height: 1.25;

    &-regular {
      @extend .heading-3;
      @extend %regular;
    }

    &-semibold {
      @extend .heading-3;
      @extend %semibold;
    }

    &-bold {
      @extend .heading-3;
      @extend %bold;
    }
  }

  // Heading 4

  &-4 {
    @include display;
    font-size: $base-font-size;
    line-height: 1.25;

    &-regular {
      @extend .heading-4;
      @extend %regular;
    }

    &-semibold {
      @extend .heading-4;
      @extend %semibold;
    }

    &-bold {
      @extend .heading-4;
      @extend %bold;
    }
  }

  // Heading 5

  &-5 {
    @include display;
    font-size: $base-font-size * 0.8;
    line-height: 1.25;

    &-regular {
      @extend .heading-5;
      @extend %regular;
    }

    &-semibold {
      @extend .heading-5;
      @extend %semibold;
    }

    &-bold {
      @extend .heading-5;
      @extend %bold;
    }
  }

  // Heading 6

  &-6 {
    @include display;
    font-size: $base-font-size * 0.7;
    line-height: 1.25;

    &-regular {
      @extend .heading-6;
      @extend %regular;
    }

    &-semibold {
      @extend .heading-6;
      @extend %semibold;
    }

    &-bold {
      @extend .heading-6;
      @extend %bold;
    }
  }
}

// Body Typography
.body-text {
  // Body Text 1
  &-1 {
    // @include paragraph;
    font-size: $base-font-size * 1.125;
    line-height: 1.5;

    &-regular {
      @extend .body-text-1;
      @extend %regular;
    }

    &-semibold {
      @extend .body-text-1;
      @extend %semibold;
    }

    &-bold {
      @extend .body-text-1;
      @extend %bold;
    }
  }

  // Body Text 2
  &-2 {
    font-size: $base-font-size;
    line-height: 1.6875;

    &-regular {
      @extend .body-text-2;
      @extend %regular;
    }

    &-semibold {
      @extend .body-text-2;
      @extend %semibold;
    }

    &-bold {
      @extend .body-text-2;
      @extend %bold;
    }
  }

  // Body Text 3
  &-3 {
    // @include paragraph;
    font-size: $base-font-size * 0.875;
    line-height: 1.9286;

    &-regular {
      @extend .body-text-3;
      @extend %regular;
    }

    &-semibold {
      @extend .body-text-3;
      @extend %semibold;
    }

    &-bold {
      @extend .body-text-3;
      @extend %bold;
    }
  }
  &-4 {
    // @include paragraph;
    font-size: $base-font-size * 0.8;
    line-height: 2.3032;

    &-light {
      @extend .body-text-4;
      @extend %light;
    }

    &-regular {
      @extend .body-text-4;
      @extend %regular;
    }

    &-semibold {
      @extend .body-text-4;
      @extend %semibold;
    }

    &-bold {
      @extend .body-text-4;
      @extend %bold;
    }
  }
  &-5 {
    // @include paragraph;
    font-size: $base-font-size * 0.7;
    line-height: 2.3032;

    &-regular {
      @extend .body-text-5;
      @extend %regular;
    }

    &-semibold {
      @extend .body-text-5;
      @extend %semibold;
    }

    &-bold {
      @extend .body-text-5;
      @extend %bold;
    }
  }
  &-6 {
    // @include paragraph;
    font-size: $base-font-size * 0.6;
    line-height: 2.3032;

    &-regular {
      @extend .body-text-6;
      @extend %regular;
    }

    &-semibold {
      @extend .body-text-6;
      @extend %semibold;
    }

    &-bold {
      @extend .body-text-6;
      @extend %bold;
    }
  }
}
