@use "../abstracts/mixins" as m;
@use "../abstracts/colors" as c;
@use "../abstracts/shadows" as s;
@use "../base/typography";

.modal1 {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  @include m.distinctSize(100vw, 100vh);
  background: rgba(0, 0, 0, 0.41);
  backdrop-filter: blur(1.5px);
  @include m.flex-config(center, center, false, false);
  border: 1px solid c.color(border);

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(1.5px);
  }

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(0, 0, 0, 0.8);
  }

  @include m.respond("tablet") {
    padding: 2.5rem;
  }

  @include m.respond("phablet") {
    padding: 2rem;
  }

  @include m.respond("phone-wide") {
    padding: 0.25rem;
  }
  &__body {
    background-color: white;
    border-radius: 10px;
    padding: 35px 42px;
    margin-bottom: 2.3rem;
    // max-height: 90vh;
    max-width: 43.2vw;
    min-width: 40vw;
    @include m.respond("phone-wide") {
      padding-right: 2rem;
      width: 100%;
    }
  }

  &__body1 {
    background-color: white;
    border-radius: 10px;
    padding: 85px 42px;
    margin-bottom: 2.3rem;
    width: calc(100vw - 63rem);
    @include m.respond("phone-wide") {
      padding-right: 2rem;
      width: 100%;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;

    &-text {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid c.color(border);
    margin-bottom: 2rem;
    &-title {
      font-size: 1.3rem;
      font-weight: normal;
    }
  }

  //  head for create project modal
  &__head1 {
    position: fixed;
    width: calc(100vw - 60rem);
    z-index: 9999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid c.color(border);
    background-color: white;
  }

  &__card {
    height: 85vh;
    overflow: scroll;
    padding-top: 2rem;
    z-index: 1;
  }

  // footer for create project
  &__footer1 {
    border-top: 1px solid c.color(border);
    left: auto;
    padding-top: 0.5rem;

    a {
      text-decoration: none;
    }
  }

  // end create project modal

  &__center {
    border: 1px solid #e9eaec;
    border-radius: 8px;
    padding-left: 10px;
    height: 20rem;
    position: relative;
    overflow-y: scroll ;

    &-title {
      position: relative;

      &-1 {
        position: absolute;
        top: 0;
        left: 0;
      }

      &-2 {
        position: absolute;
        padding-top: 1.2rem;
        left: 0;
      }
    }
  }

  // Verification create confirmation modal
  &__verification-lock {
    display: flex;
    justify-content: center;

    img {
      width: 70px;
    }
  }
  &__verification-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2rem;
    p {
      color: c.color(grey-200);
      font-size: 12px;
      text-align: center;
      padding-left: 0.2rem;
      font-weight: 500;
    }
  }

  &__verification-footer1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 2rem;
  }
  &__verification-checkbox {
    margin-top: 0.2rem;
  }

  &__verification-title {
    h5 {
      text-align: center;
      font-weight: bold;
    }
    p {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: c.color(grey-200);
    }
  }

  // Search bar component on the admin list modal
  &__search {
    border: 1px solid c.color(border);
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 15px;
    &-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
    &-icon {
      svg {
        width: 0.8rem;
        fill: c.color(primary);
        stroke: c.color(primary);
      }
    }
  }

  // admin section for the admin list modal
  &__admin {
    margin-top: 0.2rem;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    height: 48vh;
    gap: 1rem;
    overflow-y: auto;

    &-center {
      width: 10.5rem;
      height: 10.5rem;
      // border: 1px solid c.color(border);
      border-radius: 8px;
      position: relative;
      padding: 28px;
      &:hover {
        border: 1px solid c.color(primary);
      }

      &-1 {
        position: absolute;
        left: 0;
        padding-top: 3.8rem;
        padding-left: 35px;
      }

      &-2 {
        position: absolute;
        padding-left: 15px;
        padding-top: 5.2rem;
      }
      &-3 {
        position: absolute;
        padding-left: 15px;
      }
    }
    &-tick {
      position: relative;

      &::before {
        content: "\2713";
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 10px;
        margin-top: 10px;
        padding: 1px;
        color: c.color(white);
        background-color: c.color(primary);
        border-radius: 50%;
      }
    }

    &-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }

  &__edit {
    position: relative;
    &-selection {
      border: 1.456px dashed #000921;
      padding: 20px;
      border-radius: 2px;
      background: #4f269f05 0% 0% no-repeat padding-box;
      opacity: 1;
      margin-bottom: 1.5rem;
      position: relative;
      &.new-step{
        position: relative;
        opacity: 0;   
        transition: all 0.5s ease-in-out; 
        display: none;
        z-index: 1;
        .edit-new{
          cursor: pointer;
        }
      }
      &.new-step__created {
        opacity: 1;
        display: block;
      }
      &-x {
        position: absolute;
        right: 0;
        top: 0;
        width: 1.1rem;
        height: 1.1rem;
        font-size: 8px;
        border-radius: 0px;
        background-color: c.color(buttonx);
        transform: translate(50%, -50%);
        justify-content: center;
        align-items: center;
        display: none;
        cursor: pointer;
      }

      &-hide-x {
        display: none !important;
      }

      &-circle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        padding: -10px;
        margin-right: -6%;
      }

      &-message {
        font-size: 0.8rem;
        opacity: 0.3;
        margin-left: 2rem;
      }

      &-admin {
        display: flex;
        padding-left: 7px;
        padding-right: 7px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        background-color: white;
        border: 1px solid c.color(border);
        border-radius: 4px;
        padding-top: 5px;
        padding-bottom: 7px;
        &.edit-new {
          cursor: pointer;
        }
      }

      &:hover {
        .modal1__edit-selection-x {
          display: flex;
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: center;
      position: relative;
      transform: 50%;
      transition: all 0.3s ease-in-out; 
      &.translate-down {
        transform: translateY(0);

      }
      &-sign {
        border-radius: 50%;
        height: 14px;
        width: 14px;
        margin-right: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        background: c.color(primary);
        color: c.color(white);
      }
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &-header {
      p {
        font-weight: normal;
        font-size: 11px;
        margin-bottom: 0;
      }
      padding-bottom: 0.5rem;
    }
  }

  &__details {
    margin-top: 4rem;
    position: relative;

    &-row {
      margin-top: -1rem;
      position: relative;
      border-left: 1.5px dashed #00000019;
      margin-left: 1.6rem;
      height: fit-content;

      &-top {
        display: flex;
        flex-direction: row;
        // padding: 0rem 2rem;
        margin-left: 2rem;
      }

      &-number {
        position: absolute;
        left: -2.7%;
        top: 0;
        margin-right: 1rem;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        z-index: 99;
        padding-right: 1px;
        // padding-top: -5px;
        background: #171102 0% 0% no-repeat padding-box;
        border: 3px solid #000;
        color: c.color(white);
        text-align: center;
        font-size: 12px;
        font-weight: normal;
      }
      &-column {
        h5 {
          font-size: 10px;
          color: #000921;
          font-weight: normal;
        }
      }

      &-bottom {
        display: flex;
        flex-direction: row;
        margin-top: 0rem;
        margin-left: 2rem;
        margin-bottom: 1rem;
        gap: 0.2em;
        @include m.respond("phone-wide") {
          width: 100%;
        }
      }

      &-box {
        padding-left: 4px;
        padding-right: 4px;
        border: 1px solid c.color(border);
        border-radius: 4px;
        @include m.respond("phone-wide") {
          height: 2rem;
        }
        &-title {
          text-align: center;
          font-weight: normal;
          font-size: 10px;
          padding: 0.05rem 0.2rem;
          @include m.respond(phone-wide) {
            padding-bottom: 6rem;
          }
        }
      }
    }
  }

  &__border {
    padding-bottom: 0.5rem;
    margin-bottom: 0.8rem;
    border-bottom: 1px solid c.color(border);
    & .text-black {
      font-size: 0.7rem;
      margin-bottom: 0rem;
    }
  }

  &__footer {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid c.color(border);
  }

  &__footer1 {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid c.color(border);
  }

  &__btn {
    &-no {
      font-size: 12px;
      font-weight: 700;
      background-color: c.color(primary-200);
      text-align: center;
      padding: 10px 20px;
      border: none;
      @include m.radius(6px);
      box-shadow: 0;
      margin-right: 10px;
      outline: 0;
      color: c.color(primary);
    }

    &-yes {
      background-color: c.color(primary);
      text-align: center;
      font-size: 12px;
      padding: 10px 20px;
      @include m.radius(6px);
      border: none;
      box-shadow: 0;
      outline: 0;
      color: c.color(white);

      transition: box-shadow 0.2s ease;

      &:hover {
        box-sizing: s.$box-shadow-300;
      }
    }

    &-admin {
      background-color: c.color(bg-100);
      text-align: center;
      padding: 0.3rem 1rem;
      box-shadow: 0;
      height: fit-content !important;
      outline: 0;
      color: c.color(admin);
      border-radius: 2px;
      border: none;
      font-size: 10px !important;
    }

    &-discard {
      font-size: 12px;
      font-weight: 700;
      background-color: transparent;
      text-align: center;
      padding: 10px 20px;
      border: none;
      @include m.radius(6px);
      box-shadow: 0;
      // margin-right: 10px;
      outline: 0;
      color: c.color(grey-200);
    }
  }
}
